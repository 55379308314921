$header-font:'Cinzel Decorative', serif, 'small-caps';
$background-image: url('../public/images/jackie_playing_guitar_b_and_w.png');



$sidebar-bg-color: black !default;
$sidebar-color: #adadad !default;
$sidebar-width: 150px !default;
$sidebar-collapsed-width: 40px !default;
$highlight-color: #d8d8d8 !default;
$submenu-bg-color: #2b2b2b !default;
$submenu-bg-color-collapsed: #2b2b2b !default;
$icon-bg-color: #2b2b2b !default;
$icon-size: 35px !default;
$submenu-indent: 24px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;

@import '~react-pro-sidebar/dist/scss/styles.scss';

.app {
  text-align: center;
  background-image: $background-image;
  background-color: black;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: auto;
}

.app-text {
  color: white;
  font-size: 5vw;
  letter-spacing: .05em;
  font-family: $header-font;
  font-variant: small-caps;
  padding-right: 30px;
  text-align: right;
  z-index: 0;
  position: fixed;
  top: 10px;
  left: 875px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .app-text {
    color: white;
    font-size: 2vw;
    letter-spacing: .05em;
    font-family: $header-font;
    font-variant: small-caps;
    padding-right: 50px;
    text-align: center;
    z-index: 0;
    // position: fixed;
    // top: 10px;
    // left: 875px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {...}

/* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {...}

/* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {...}

/* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {...}
  
// .music-body {
//   background-color: #adadad85;
//   color: black;
//   z-index: 10;
// }

  // sideNav
.side-bar {
  background-color: $sidebar-bg-color;
  size: $sidebar-width;
  text-align: left;
  padding-top: 45px;
  margin-left: 20px;
  //z-index: 20;
  //position: -webkit-sticky; /* Safari */
  //position: sticky;
  position: fixed;
  top: 10px;
}

.navLogo {
  height: 5rem;
  width:auto;
  padding-left: 10px;
}

  @media (prefers-reduced-motion: no-preference) {
    .app-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .app-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .app-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  


  @media screen and (max-width: 575px) {
  
    .app-text {
      text-align: center;  
    }
    
   }
  
   @media screen and (min-width: 576px) {
  
   }
  
   @media screen and (min-width: 992px) {
    .bigPictureText {
      font-size: 6vw;
    }
   }
  
   @media screen and (min-width: 1200px) {
    .bigPictureText {
      font-size: 5.5vw;
    }
   }
  
  //  music player
  // @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,100;1,400&display=swap");

// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }

// html {
//   font-size: 16px;
// }

// a {
//   text-decoration: none;
// }

// body {
//   font-family: "Poppins", sans-serif;
//   color: #071739;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   text-align: center;
//   //background: url("./box.png") repeat 0 0;
//   animation: background-scroll 6s infinite;
//   animation-timing-function: linear;
// }

// /* Player body */

// .music-player {
//   margin-top: 7rem;
//   margin-right: auto;
//   margin-left: auto;
//   max-width: 30rem;
//   background: #fff;
//   border-radius: 1.25rem;
//   box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.19),
//     0 6px 6px -10px rgba(0, 0, 0, 0.23);
// }
// f
// /* body cover */
// .music-player--details {
//   position: relative;
// }

// .details-img img {
//   max-width: 100%;
//   border-radius: 0.25rem;
// }

// /* Progress bar */

// .range {
//   position: relative;
//   top: 0.5rem;
//   right: 0;
//   left: 0;
//   margin: auto;
//   background: rgba(173, 173, 173, 0.95);
//   width: 100%;
//   height: 0.125rem;
//   border-radius: 0.25rem;
//   cursor: pointer;
// }
// .range:before,
// .range:after {
//   content: "";
//   position: absolute;
//   cursor: pointer;
// }
// .range:before {
//   width: 3rem;
//   height: 100%;
//   background: linear-gradient(
//     to right,
//     rgba(211, 3, 32, 0.5),
//     rgba(211, 3, 32, 0.85)
//   );
//   border-radius: 0.25rem;
//   overflow: hidden;
// }
// .range:after {
//   top: -0.375rem;
//   left: 0.2rem;
//   z-index: 3;
//   width: 0.875rem;
//   height: 0.875rem;
//   background: #fff;
//   border-radius: 50%;
//   box-shadow: 0 0 3px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.15);
//   transition: all 0.25s cubic-bezier(0.4, 0, 1, 1);
// }
// .range:focus:after,
// .range:hover:after {
//   background: rgba(211, 3, 32, 0.95);
// }

// /* Controls */

// .music-player--controls {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-bottom: 35px;
// }

// .music-player--controls .play-btn {
//   padding: 2rem;
//   margin-left: 2rem;
//   margin-right: 2rem;
//   font-size: 1rem;
//   border: none;
//   outline: none;
//   background: none;
//   border-radius: 50%;
//   box-shadow: 0 3px 6px rgba(33, 33, 33, 0.1), 0 3px 12px rgba(33, 33, 33, 0.15);
//   transition: all 0.25s cubic-bezier(0.4, 0, 1, 1);
// }

// .play-btn:focus {
//   color: rgba(171, 2, 26, 0.95);
//   opacity: 1;
//   box-shadow: 0 6px 9px rgba(33, 33, 33, 0.1), 0 6px 16px rgba(33, 33, 33, 0.15);
// }

// .play-btn:hover {
//   transform: scale(1.2);
//   color: rgba(171, 2, 26, 0.95);
//   opacity: 1;
//   box-shadow: 0 6px 9px rgba(33, 33, 33, 0.1), 0 6px 16px rgba(33, 33, 33, 0.15);
// }

// .music-player--controls .skip-btn {
//   padding: 0.7rem;
//   font-size: 1rem;
//   border: none;
//   outline: none;
//   background: none;
//   border-radius: 50%;
//   box-shadow: 0 3px 6px rgba(33, 33, 33, 0.1), 0 3px 12px rgba(33, 33, 33, 0.15);
//   transition: all 0.25s cubic-bezier(0.4, 0, 1, 1);
// }
// .skip-btn:focus {
//   color: rgba(171, 2, 26, 0.95);
//   opacity: 1;
//   box-shadow: 0 6px 9px rgba(33, 33, 33, 0.1), 0 6px 16px rgba(33, 33, 33, 0.15);
// }

// .skip-btn:hover {
//   /* transform: scale(1.5); */
//   color: rgba(171, 2, 26, 0.95);
//   opacity: 1;
//   box-shadow: 0 6px 9px rgba(33, 33, 33, 0.1), 0 6px 16px rgba(33, 33, 33, 0.15);
// }

// /* Image */
// .details-img--image {
//   width: 25rem;
//   height: auto;
// }

// /* Artist Info */

// .artist-info {
//   padding-top: 1.5rem;
//   padding-bottom: 1.25rem;
//   text-align: center;
// }

// .details-title {
//   margin-bottom: 0.5rem;
// }

// .details-artist {
//   font-size: 0.75rem;
//   font-weight: 300;
//   color: #666;
// }

// .info__song {
//   font-size: 1.15rem;
//   font-weight: 400;
//   color: #d30320;
// }

/* Footer */

.list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.list__link {
  transition: all 0.25s cubic-bezier(0.4, 0, 1, 1);
}
.list__link:focus,
.list__link:hover {
  color: #d30320;
}

.player__footer {
  padding-top: 1rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  padding-left: 2rem;
}

.list--footer a {
  opacity: 0.5;
}
.list--footer a:focus,
.list--footer a:hover {
  opacity: 0.9;
}
.list--footer {
  justify-content: space-between;
}

/* Next songs */

.text-anim {
  font-size: 23px;
  font-weight: 550;
  color: black;
  text-align: center;
  animation: effect 3s linear infinite;
  padding-bottom: 20px;
}

.nextsong-details {
  position: absolute;
  display: flex;
  flex-direction: row;
  padding: 1px;
  height: 4em;
}

.nextsong-details img {
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.nextsong-details p {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  align-items: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8),
    -2px -2px 4px rgba(129, 129, 129, 0.4);
}

/* Animation */

@-webkit-keyframes background-scroll {
  100% {
    background-position: 50px 50px;
  }
}
@-moz-keyframes background-scroll {
  100% {
    background-position: 50px 50px;
  }
}
@-o-keyframes background-scroll {
  100% {
    background-position: 50px 50px;
  }
}
@keyframes background-scroll {
  100% {
    background-position: 50px 50px;
  }
}
  
// @import '../node_modules/bootstrap/scss/bootstrap';
// .app-text {
//   @include font-size(4rem);
// }
  
  /* Temp Home Page */
.temp-h1 {
  padding-top: 1rem;
  text-align: center;
  font-family: 'Cinzel Decorative', serif;
  font-variant: small-caps;
  font-size: 4rem;
}
.temp-h2 {
  font-family: 'Quicksand', sans-serif;
  font-size: 2rem;
  text-align: center;
}

.temp-h3 {
  font-family: 'Quicksand', sans-serif;
  font-size: 1.5rem;
  text-align: center;
}

.temp-h3-info {
  font-family: 'Quicksand', sans-serif;
  font-size: 1.25rem;
  text-align: center;
  color: #4c4b4b;
}

.temp-h4 {
  font-family: 'Arima', cursive;
  font-size: 1.5rem;
  text-align: center;
}

.temp-h4-holiday {
  font-family: 'Arima', cursive;
  font-style: italic;
  font-size: 1.5rem;
  text-align: center;
  color: green;
}

.temp-h5 {
  font-family: 'Quicksand', sans-serif;
  font-size: 1rem;
  text-align: center;
  color: #818589;
}

.temp-show-link {
  font-family: 'Arima', cursive;
  font-size: 1.5rem;
  text-align: center;
  color: #636363;
}

.temp-show-link:hover {
  color:rgb(2, 168, 168)
}

.temp-music-link {
  font-family: 'Arima', cursive;
  font-size: 1.5rem;
  text-align: center;
  color: #636363;
}

.temp-music-link:hover {
  color:rgb(2, 168, 168)
}

.temp-photos-by-link {
  color:#939393
}

.temp-photos-by-link:hover {
  color:rgb(2, 168, 168)
}

.temp-info-link {
  color: black
}

.temp-info-link:hover {
  color:rgb(2, 168, 168)
}

.temp-p {
  font-family: 'Arima', cursive;
  font-size: 1.25rem;
  text-align: center;
  padding-left: 30px;
  padding-right: 10px;
}

.temp-hr-tag {
  width: 75%;
  border-width: 2px;
}

.bio-img {
  align-items: center;
  justify-content: space-around;
}

#temp-img {
  border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
  border:solid 5px rgb(2, 168, 168);
}

#temp-piano-img {
  margin-top: 55px;
  margin-left: 40px;
  border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
  border:solid 5px rgb(2, 168, 168);
  height: 80%;
  width: 80%;
}

.sound-cloud-player {
  padding-left: 30px;
  padding-right: 10px;
}

/* MEDIA QUERIES */

@media screen and (max-width: 700px) {
  
  .temp-p {
    padding-right: 30px;
  }

  #temp-piano-img {
    height: 50%;
    width: 50%;
    margin-top: 15px;
    margin-left: 0px;
  }

}

@media screen and (max-width: 992) {

  #temp-piano-img {
    height: 100%;
    width: 100%;
    margin-top: 0px;
    margin-left: 0px;
    
  }

  .bio-img {
    align-items: center;
    justify-content: flex-start;
  }

}


